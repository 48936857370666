<template>
  <div>
    <v-card :disabled="mode === 'loading'">
      <v-card-title v-if="mode === 'loading'">
        Loading...
      </v-card-title>
      <v-card-title v-if="mode === 'create'">
        Create Runner Job
      </v-card-title>
      <v-card-title v-if="mode === 'edit'">
        Edit Runner Job
      </v-card-title>

      <v-card-text>
        <v-form
          ref="jobForm"
          v-model="validForm"
          lazy-validation
        >
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Name"
            required
          />

          <v-select
            v-model="type"
            :items="[{ value: 'kubernetes', text: 'Kubernetes' }]"
            :rules="typeRules"
            label="Type"
            required
          />

          <v-select
            v-model="image"
            :disabled="containerImages.length === 0"
            :items="containerImages"
            :rules="imageRules"
            label="Container Image"
            required
          />

          <v-select
            v-model="commandType"
            :items="[{ value: '/job/run.sh', text: '/job/run.sh' }]"
            :rules="commandTypeRules"
            label="Command Type"
            required
          />

          <v-text-field
            v-model="command"
            :rules="commandRules"
            label="Command"
            required
          />

          <v-select
            v-model="sqlProxy"
            :disabled="sqlProxyList.length === 0"
            :items="sqlProxyList"
            item-value="id"
            item-text="name"
            label="SQL Proxy"
            multiple
            required
          />

          <v-select
            v-model="secret"
            :disabled="containerSecrets.length === 0"
            :items="containerSecrets"
            label="Secret"
            required
          />

          <v-slider
            v-model="timeout"
            :thumb-size="20"
            :min="10"
            :max="600"
            :step="5"
            thumb-label="always"
            label="Timeout"
          />

          <v-slider
            v-model="memory"
            :thumb-size="30"
            :min="32"
            :max="16384"
            :step="32"
            thumb-label="always"
            label="Memory (MiB)"
          />

          <v-slider
            v-model="storage"
            :thumb-size="30"
            :min="0"
            :max="512"
            :step="2"
            thumb-label="always"
            label="Ephemeral Storage (GiB)"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          class="mr-4"
          @click="cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          @click="submitForm"
        >
          {{ mode === 'create' ? 'Create Job' : 'Edit Job' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import { db } from '../../../../firestore';
import KubernetesImage from '../../../../model/kubernetes/Image';
import Job from '../../../../model/runner/Job';
import KubernetesSecret from '../../../../model/kubernetes/Secret';

const kubernetesImage = new KubernetesImage();
const kubernetesSecret = new KubernetesSecret();

export default {
  name: 'Home',
  data() {
    return {
      containerImages: [],
      containerSecrets: [],
      command: '',
      commandRules: [
        v => !!v || 'Please enter a job command',
      ],
      commandType: '/job/run.sh',
      commandTypeRules: [
        v => !!v || 'Please select the command type',
      ],
      image: '',
      imageRules: [
        v => !!v || 'Please select the container image',
      ],
      memory: '64',
      name: '',
      nameRules: [
        v => !!v || 'Please enter a job name',
        v => !!v.match(/^[a-z0-9-]*$/) || 'Name can only include lowercase alphanumeric characters and dashes',
        v => !!this.nameTaken || this.nameTaken,
      ],
      nameTaken: true,
      secret: '',
      sqlProxy: [],
      storage: '0',
      timeout: '60',
      type: 'kubernetes',
      typeRules: [
        v => !!v || 'Please select the job type',
      ],
      mode: 'loading',
      sqlProxyList: Job.sqlProxyList(),
      validForm: true,
    };
  },
  computed: {
    jobData() {
      const data = {
        command: this.command,
        commandType: this.commandType,
        image: this.image,
        memory: this.memory,
        name: this.name,
        secret: this.secret,
        sqlProxy: this.sqlProxy,
        storage: this.storage,
        timeout: this.timeout,
        type: this.type,
        updated: new Date(),
      };

      return data;
    },
    jobID() {
      return this.$route.params.jobID;
    },
  },
  mounted() {
    if (this.jobID) {
      db.collection('shepherd-job').doc(this.jobID).get().then((job) => {
        if (!job.exists) {
          this.$notify({
            type: 'error',
            title: 'Missing Job',
            text: `Cannot find a job in the system under the ID: ${this.jobID}`,
          });
          this.$router.push({ name: 'runner-job-list' });
        } else {
          for (const key of Object.keys(job.data())) {
            this[key] = job.data()[key];
          }
          this.mode = 'edit';
        }
      });
    } else {
      this.mode = 'create';
    }
    kubernetesImage.getAllImages().then((images) => {
      for (const image of images) {
        this.containerImages.push({
          value: image.image,
          text: image.name,
        });
      }
    });
    kubernetesSecret.getJobSecretList().then((secrets) => {
      Object.keys(secrets).forEach((secretId) => {
        this.containerSecrets.push({
          value: secretId,
          text: secrets[secretId],
        });
      });
    });
  },
  methods: {
    cancel() {
      switch (this.mode) {
        case 'create':
          this.$router.push({
            name: 'runner-job-list',
          });
          break;

        case 'edit':
          this.$router.push({
            name: 'runner-job-detail',
            params: { jobID: this.jobID },
          });
          break;
        default:
          this.$router.push({
            name: 'home',
          });
          break;
      }
    },
    submitForm() {
      if (this.$refs.jobForm.validate()) {
        const data = this.jobData;
        switch (this.mode) {
          case 'create':
            db.collection('shepherd-job')
              .add({
                ...data,
                status: 0,
              })
              .then((document) => {
                this.$router.push({
                  name: 'runner-job-detail',
                  params: { jobID: document.id },
                });
              });
            break;

          case 'edit':
            db.collection('shepherd-job')
              .doc(this.jobID)
              .update(data)
              .then(() => {
                this.$router.push({
                  name: 'runner-job-detail',
                  params: { jobID: this.jobID },
                });
              });
            break;
          default:
            this.$notify({
              type: 'error',
              title: 'Unknown Mode',
              text: `Cannot action unknown mode: ${this.mode}`,
            });
            break;
        }
      } else {
        this.$notify({
          type: 'warning',
          title: 'Validation Failed',
          text: `Please correct the errors with your form`,
        });
      }
    },
  },
  watch: {
    name(value) {
      const firestore = db;
      this.nameTaken = true;
      const validRegEx = /^[a-z]+(?:-?[a-z]+)+$/;
      if (value === '') {
        this.nameTaken = 'Please enter the job name';
      } else if (!validRegEx.test(value)) {
        this.nameTaken = 'Names can only contain lowercase letters and hyphens';
      } else {
        firestore.collection('shepherd-job')
          .where('name', '==', value)
          .get()
          .then((existing) => {
            if (existing.size > 0 && existing.docs[0].id !== this.jobID) {
              this.nameTaken = 'A job with this name already exists';
              this.$refs.jobForm.validate();
            }
          });
      }
    },
  },
};
</script>
