<template>
  <div>
    <v-card :loading="tableData === null">
      <v-card-title v-if="tableData === null">
        Loading Code Vulnerability List...
      </v-card-title>
      <v-card-title v-else>
        Code Vulnerability List
        <v-spacer />
        <v-switch
         v-model="showZeroCritialHigh"
         dense
         hide-details
         label="Show 0 Critial & High"
        />
        <v-switch
         v-model="showNonProductionBranches"
         dense
         hide-details
         label="Show Non Production Branches"
         class="pl-5"
        />
      </v-card-title>
      <f-table v-if="tableData" :items="filteredTableData" :columns="columns">
        <template v-slot:fullName="{ item }">
          <span v-if="item.fullName.substring(0, 17) === 'FundamentalMedia/'">{{ item.fullName.substring(17) }}</span>
          <span v-else>{{ item.fullName }}</span>
        </template>
        <template v-slot:branch="{ item }">
          <a :href="`https://github.com/${item.fullName}/tree/${item.branch}`" target="_blank">{{ item.branch }}</a>
        </template>
        <template v-slot:gcpImageUrl="{ item }">
          <a :href="`https://${item.gcpImageUrl}`" target="_blank">{{ item.gcpImageUrl }}</a>
        </template>
      </f-table>
    </v-card>
  </div>
</template>

<script>
import CodeVulnerability from '../../../../model/code/Vulnerability';

const codeVulnerability = new CodeVulnerability();

export default {
  name: 'CodeVulnerabilityList',
  data() {
    return {
      columns: [
        {
          heading: 'Repo Name',
          filter: "select",
          value: 'fullName',
        },
        {
          heading: 'Branch',
          filter: "select",
          value: 'branch',
        },
        {
          heading: 'Image URL',
          value: 'gcpImageUrl',
        },
        {
          heading: 'Critical',
          value: 'vulnCritical',
        },
        {
          heading: 'High',
          value: 'vulnHigh',
        },
        {
          heading: 'Medium',
          value: 'vulnMedium',
        },
        {
          heading: 'Low',
          value: 'vulnLow',
        },
      ],
      showNonProductionBranches: false,
      showZeroCritialHigh: false,
      tableData: null,
    };
  },
  created() {
    codeVulnerability.getRepoVulnerability().then((res) => {
      this.tableData = res;
    })
  },
  computed: {
    filteredTableData() {
      return this.tableData.filter((item) => {
        if (!this.showZeroCritialHigh && item.vulnCritical <= 0 && item.vulnHigh <= 0) return false;
        if (!this.showNonProductionBranches && !['master', 'main', 'main/staging'].includes(item.branch)) return false;
        return true;
      });
    },
  },
};
</script>
